@font-face {
  font-family: 'AntapaniExtraBold';
  src: url('/assets/fonts/Antapani-ExtraBold.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'AntapaniSemiBold';
  src: url('/assets/fonts/Antapani-SemiBold.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'AntapaniRegular';
  src: url('/assets/fonts/Antapani-Regular.ttf') format('truetype');
  font-weight: normal;
}
