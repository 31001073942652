@use '@angular/material' as mat;

@import 'palette';
@import 'variables';
@import 'general';

@include mat.core();

$gg-primary: mat.define-palette($gg-primary-palette);
$gg-accent: mat.define-palette($gg-secondary-palette);
$gg-warn: mat.define-palette($gg-warn-palette);

$gg-typography: mat.define-legacy-typography-config(
  $font-family: $font-family-gg-regular,
);

$gg-theme: mat.define-light-theme(
  (
    color: (
      primary: $gg-primary,
      accent: $gg-accent,
      warn: $gg-warn,
    ),
    typography: $gg-typography,
    density: 0,
  )
);

@include mat.all-component-themes($gg-theme);

// ============================ Overrides ============================

// Checkbox

.gg-checkbox,
.mat-mdc-checkbox {
  height: 24px;

  .mdc-form-field {
    height: 24px !important;
  }

  .mdc-label {
    height: 24px;
    font-family: $font-family-gg-regular;
    font-size: $font-size-xs;
    color: $color-black;
    letter-spacing: 0;
    margin-top: 3px;
  }

  &.mat-mdc-checkbox-checked {
    .mdc-checkbox__background {
      border-color: $color-primary !important;
    }
  }

  .mdc-checkbox__checkmark-path {
    stroke: $color-primary !important;
    d: path('M 6 11.7 L 11 16.6 L 18 8');
  }

  .mat-mdc-checkbox-2-input {
    height: 24px;
    font-family: $font-family-gg-regular;
    font-size: $font-size-xs;
    color: $color-black;
    letter-spacing: 0;
    margin-top: 3px;
  }

  .mat-ripple,
  .mdc-checkbox__ripple {
    display: none;
  }

  &.mat-mdc-checkbox-checked {
    .mat-mdc-checkbox-frame {
      border-color: $color-primary;
    }
  }
}

// Button

.mdc-button {
  .mat-mdc-focus-indicator,
  .mat-mdc-button-touch-target {
    display: none !important;
  }
}

.mat-raised-button:not(:active) {
  box-shadow: none !important;
}

.mat-mdc-button-ripple,
.mat-mdc-button-persistent-ripple {
  display: none !important;
}

// Select

.mat-mdc-select {
  .mat-mdc-select-placeholder {
    font-family: $font-family-gg-regular;
    font-size: $font-size-xs;
    color: $color-gray;
    letter-spacing: 0;
  }
}

// Dialog

.gg-dialog-backdrop {
  background: $color-backdrop;
}

.gg-dialog-panel {
  border-radius: 10px;

  .mat-mdc-dialog-surface {
    padding: 20px;
    height: auto;
    background: $color-white;
    box-shadow: 0 5px 30px 0 $color-box-shadow-lighter;
    border-radius: 10px !important;
  }
}

.class-popup-panel {
  border-radius: 10px;
}

.scrollable-popup {
  overflow: auto !important;
}

// Menu

.mat-mdc-menu-panel {
  min-height: 0 !important;
  background: $color-white;
}

.mat-mdc-menu-content {
  padding: 0 !important;
}

.mdc-menu-surface {
  background: $color-white;
  box-shadow: 0 5px 30px 0 $color-box-shadow-light;
}

.filters-menu-panel {
  width: 330px;
  margin-top: 10px;
  max-height: 370px;
  border-radius: 10px !important;
  box-shadow: 0 5px 30px 0 $color-box-shadow-light;
  .mat-mdc-menu-content {
    padding: 10px !important;
  }
}

.timepicker-menu-panel {
  margin-top: 10px;
  max-width: none !important;
  border-radius: 10px !important;
  box-shadow: 0 5px 30px 0 $color-box-shadow-light;
  .mat-mdc-menu-content {
    padding: 20px !important;
  }
}

.cdk-overlay-pane .common-menu-panel {
  background: $color-white;
  min-width: 170px !important;
  max-height: 170px !important;
  border-radius: 10px !important;
  box-shadow: 0 5px 30px 0 $color-box-shadow-light !important;

  .mat-mdc-menu-content {
    padding: 10px !important;

    .mat-mdc-menu-item {
      height: 32px;
      padding: 0 5px;
      min-width: 110px;
      line-height: 32px;
      border-radius: 4px;
      min-height: 32px !important;

      .mat-mdc-menu-item-text {
        @extend .xs-content-text;
      }

      &.active {
        color: $color-primary;
        background: $color-light-blue;
      }

      &:hover:not(.active) {
        color: $color-black !important;
        background: $color-light-gray;
      }
    }
  }
}

.full-items-list-menu-panel {
  min-width: 170px !important;
  max-height: 160px !important;
  border-radius: 4px !important;
  background: $color-tooltip-background;
  box-shadow: 0 5px 20px 0 $color-tooltip-shadow;

  .mat-mdc-menu-content {
    padding: 0px !important;
  }
}

// Autocomplete
.mat-mdc-autocomplete-panel {
  &.mdc-menu-surface {
    max-height: 180px !important;
  }
}

// Tooltip
.mdc-tooltip {
  font-size: $font-size-xxs;
  font-family: $font-family-gg-regular;
  background: rgba(0, 0, 0, 0.9);
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  &.gg-tooltip-auto-width {
    .mdc-tooltip__surface {
      max-width: max-content;
    }
  }
}

// Snackbar
.mat-mdc-snack-bar-container {
  border-radius: 10px;

  &.success {
    .mdc-snackbar__surface {
      background: $color-success !important;
    }
  }

  &.failure {
    .mdc-snackbar__surface {
      background: $color-warn !important;
    }
  }

  .mat-mdc-simple-snack-bar {
    .mdc-snackbar__label {
      font-family: $font-family-gg-regular;
      font-size: $font-size-xs;
      color: $color-white;
      letter-spacing: 0;
      line-height: 20px;
      padding-bottom: 12px;
      padding-top: 12px;
    }
  }
}

// Datepicker
.mat-datepicker-content-container {
  padding: 15px;
}

.mat-datepicker-content {
  background: $color-white;
  box-shadow: 0 5px 30px 0 $color-box-shadow-light;
  border-radius: 10px !important;
}

// Calendar
@mixin mat-calendar-body-cell-dimensions {
  top: 3px !important;
  height: calc(100% - 6px) !important;
}

.mat-calendar {
  font-size: 16px;

  &.wlcm-calendar-disabled {
    opacity: 0.5;
    position: relative;
    pointer-events: none;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .mat-calendar-body-label,
  .mat-calendar-body-cell-container {
    padding-top: 5.836734693877551% !important;
    padding-bottom: 5.836734693877551% !important;

    .mat-calendar-body-cell {
      &::before,
      &::after,
      .mat-calendar-body-cell-content,
      .mat-calendar-body-cell-preview {
        @include mat-calendar-body-cell-dimensions;
      }
    }
  }

  mat-year-view {
    .mat-calendar-body-label {
      display: none;
    }
  }

  .mat-calendar-body-label {
    opacity: 0;

    &[colspan='7'] {
      display: none;
    }
  }

  .mat-calendar-content {
    padding: 0 !important;
  }

  .mat-calendar-table-header-divider {
    display: none;
  }

  .mat-calendar-table-header th {
    font-size: 13px;
    color: #676767;
    font-family: $font-family-gg-regular;
    text-align: center;
    letter-spacing: 0;
  }

  .mat-calendar-body-cell-content {
    font-size: 13px;
    border-radius: 4px;
    color: $color-black;
    font-family: $font-family-gg-regular;
  }

  .mat-calendar-body-selected {
    background-color: $selected-option-color;
  }

  .mat-calendar-body-today {
    border: none;
  }

  .mat-calendar-body-in-range {
    &::before {
      background: $selected-option-color;
    }

    .mat-calendar-body-cell-content {
      color: $color-primary;
    }
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background: $selected-option-color;
  }

  .mat-calendar-body-in-preview {
    .mat-calendar-body-cell-preview {
      border: none;
      background: $selected-option-color;
    }
  }

  .mat-calendar-body-selected.mat-calendar-body-today {
    box-shadow: none;
  }

  .cdk-program-focused
    .mat-calendar-body-active
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: transparent;
    border: 1px solid $color-primary;
  }
}
