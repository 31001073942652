.gg-common-card {
  background: $color-white;
  box-shadow: 0 5px 30px 0 $color-box-shadow-light;
  border-radius: 10px;
  padding: 15px;

  &__gray {
    background: $color-light-gray;
  }
}
