@use '@wlcm/angular' as wlcm;

@import 'index.scss';

@include wlcm.core();

@include wlcm.all-component-themes(
  (
    color: (
      primary: $color-primary,
      accent: $color-secondary,
      warn: $color-warn,
    ),
    specific-color: (
      field-outline-color: $color-secondary,
      focus-field-outline-color: $color-dark-azure,
      hover-field-outline-color: $color-secondary,
      placeholder-color: $color-gray,
      selected-option-color: $selected-option-color,
      field-text-color: $color-black,
      text-black-color: $color-black,
    ),
    typography: (
      bold: $font-family-gg-extra-bold,
      medium: $font-family-gg-semi-bold,
      regular: $font-family-gg-regular,
    ),
  )
);

@import './styles/overrides/wlcm-overrides';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: $font-family-gg-regular;
}
