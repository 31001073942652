@import 'variables';

.gg-form-input {
  width: 100%;
  height: 32px;
  background: $color-white;
  border: 1px solid $color-secondary;
  font-family: $font-family-gg-regular;
  border-radius: 4px;
  padding: 0 8px;
  font-size: $font-size-xs;
  color: #000000;
  letter-spacing: 0;
  box-sizing: border-box;
  outline: none;

  &:focus {
    border-color: $color-dark-azure;
  }

  &.invalid,
  &.ng-invalid {
    &.touched {
      border-color: $color-warn;
    }
  }

  &::placeholder {
    font-size: $font-size-xs;
    color: $color-gray;
    letter-spacing: 0;
  }

  &:disabled {
    opacity: 0.7;
  }
}

.gg-form-textarea {
  @extend .gg-form-input;
  height: auto;
  padding: 10px 8px;
  display: block;
}

.gg-error-text {
  color: $color-warn;
  font-size: $font-size-form-field-error;
  line-height: 12px;
}

.disabled-content {
  position: relative;
  user-select: none;
  filter: opacity(30%);
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
