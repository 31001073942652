@import 'variables';

// Form field / Input
.wlcm-label {
  font-size: $font-size-xs;
  line-height: $font-size-xs;
  font-family: $font-family-gg-regular;
  color: $color-black;
  letter-spacing: 0;
  margin-bottom: 8px;
}

.wlcm-form-field-input {
  height: 13px;
  line-height: 13px;
  font-size: $font-size-xs;
  display: block;
}

textarea.wlcm-form-field-input {
  height: auto;
  line-height: 18px;
}

.wlcm-field-container {
  height: 32px;
  padding: 0 8px;

  &:has(textarea) {
    height: auto;
    padding: 9px 8px 8px 8px;
  }
}

// Radio Button
.mat-mdc-radio-button .mdc-radio__background {
  width: 18px !important;
  height: 18px !important;
}

.mat-mdc-radio-button .mdc-radio__outer-circle {
  background-color: $color-white;
}

.mat-mdc-radio-button {
  .mdc-radio__inner-circle {
    top: -1px !important;
    left: -1px !important;
  }

  &.mat-mdc-radio-checked .mdc-radio__inner-circle {
    transform: scale(0.7) !important;
  }
}

// Select
wlcm-select {
  height: 13px;

  .mat-mdc-select-trigger .mat-mdc-select-min-line {
    font-size: $font-size-xs;
  }
}

// Option
.mat-pseudo-checkbox.mat-pseudo-checkbox-checked::after {
  top: -1.5px !important;
}

.mat-mdc-option {
  border-radius: 4px;
}

// Table
.wlcm-table-head-data,
.wlcm-table-head-actions {
  height: 15px;
  padding: 0 15px;
  font-size: $font-size-xs;
}

.wlcm-table-head-data,
.wlcm-table-head-actions {
  background-color: transparent;
}

.wlcm-table-row-spacer {
  height: 10px;
}

.wlcm-table-row-data,
.wlcm-table-row-actions {
  font-size: $font-size-xs;
  background-color: $color-light-gray;

  &:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  &:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}

.wlcm-table-row-selected {
  .wlcm-table-row-data,
  .wlcm-table-row-actions {
    background-color: $selected-option-color;
  }
}

.wlcm-table-row-data {
  padding: 15px;
}

.wlcm-table-row-actions {
  padding: 15px 10px 15px 15px;
}

.wlcm-table-head-actions {
  padding-right: 10px;
}

.wlcm-table-row-data {
  height: 80px;
  box-sizing: border-box;
}

// Button
.wlcm-button {
  .wlcm-button__content {
    font-family: $font-family-gg-semi-bold;
  }

  &--primary {
    &.wlcm-button--disabled {
      opacity: 0.5;
    }
  }

  &--secondary {
    color: $color-black;
  }

  &--large {
    padding: 0 12px;
  }
}
