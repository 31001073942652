@use '@angular/material' as mat;

@import 'palette';

// COLORS

$color-primary: mat.get-color-from-palette($gg-primary-palette, 500);
$color-warn: mat.get-color-from-palette($gg-warn-palette, 500);

$color-primary-light: #12385d;

$color-secondary: #dddddd;
$color-secondary-dark: #a6a6a6;
$color-white: #ffffff;
$color-black: #000000;
$color-gray: #676767;
$color-green: #c5e8e8;
$color-yellow: #fcecd8;
$color-violet: #dbd5e8;
$color-blue: #d9e8f0;
$color-pink: #ffcbba;

$color-light-gray: #f4f4f4;
$color-light-green: #e2f7f7;
$color-light-yellow: #fff5e9;
$color-light-violet: #dbd5e8b3;
$selected-option-color: #ecf4fd;
$color-light-blue: #ecf4fd;
$color-light-pink: #ecd9d3;

$color-dark-blue: #c7e0f9;
$color-dark-pink: #f28e6d;

$color-dark-azure: #b3d2f1;

$color-box-shadow-dark: rgba(0, 0, 0, 0.15);
$color-box-shadow-light: rgba(0, 0, 0, 0.1);
$color-box-shadow-lighter: rgba(0, 0, 0, 0.5);
$color-sidenav-menu-title: #4b7197;
$color-backdrop: rgba(0, 0, 0, 0.5);
$color-tooltip-background: rgba(0, 0, 0, 0.9);
$color-tooltip-shadow: rgba(0, 0, 0, 0.25);
$color-success: #4bb543;

// FONT SIZES

$font-size-xxxs: 10px;
$font-size-xxs: 11px;
$font-size-xs: 13px;
$font-size-sm: 14px;

$font-size-md: 16px;

$font-size-lg: 20px;
$font-size-xl: 24px;
$font-size-xxl: 32px;

$font-size-form-field-error: 12px;

// FONT FAMILY

$font-family-gg-extra-bold: 'AntapaniExtraBold';
$font-family-gg-semi-bold: 'AntapaniSemiBold';
$font-family-gg-regular: 'AntapaniRegular';

// SIDENAV

$sidenav-expanded-width: 220px;
$sidenav-minimized-width: 50px;
$sidenav-transition: all 0.2s ease;

// Breakpoints
$mobile-bp: 767px;
