svg {
  display: block;
}

.cursor-pointer {
  cursor: pointer;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  font-weight: inherit;
}

@mixin common-text-params {
  &--gray {
    color: $color-gray;
  }
}

.gg-label-text {
  display: block;
  font-size: $font-size-xs;
  line-height: $font-size-xs;
  color: $color-black;
  letter-spacing: 0;
  margin-bottom: 8px;

  @include common-text-params;
}

.asterisk {
  font-size: inherit;
  color: #fc612e;
  letter-spacing: 2px;
}

.xs-title-text {
  font-size: $font-size-xs;
  color: $color-black;
  letter-spacing: 0;
  line-height: $font-size-xs;
  font-family: $font-family-gg-semi-bold;
}

.sm-title-text {
  font-size: $font-size-sm;
  color: $color-black;
  letter-spacing: 0;
  margin: 0 0 15px 0;
  line-height: $font-size-sm;
  font-family: $font-family-gg-semi-bold;
}

.md-title-text {
  font-size: $font-size-md;
  color: $color-black;
  letter-spacing: 0;
  margin: 0 0 15px 0;
  line-height: $font-size-md;
  font-family: $font-family-gg-semi-bold;
}

.lg-title-text {
  font-family: $font-family-gg-extra-bold;
  line-height: $font-size-lg;
  font-size: $font-size-lg;
  color: $color-black;
  letter-spacing: 0;
  padding-top: 3px;
}

.xl-title-text {
  font-family: $font-family-gg-extra-bold;
  line-height: $font-size-xl;
  font-size: $font-size-xl;
  color: $color-black;
  letter-spacing: 0;
  padding-top: 3px;
}

.xxl-title-text {
  font-family: $font-family-gg-extra-bold;
  line-height: $font-size-xxl;
  font-size: $font-size-xxl;
  color: $color-black;
  letter-spacing: 0;
}

.sm-content-text {
  font-family: $font-family-gg-regular;
  font-size: $font-size-sm;
  color: $color-black;
  letter-spacing: 0;

  @include common-text-params;
}

.xs-content-text {
  font-family: $font-family-gg-regular;
  font-size: $font-size-xs;
  line-height: $font-size-xs;
  color: $color-black;
  letter-spacing: 0;

  @include common-text-params;
}

.xxs-content-text {
  font-size: $font-size-xxs;
  line-height: $font-size-xxs;
  letter-spacing: 0;
  color: inherit;

  @include common-text-params;
}

.xxxs-content-text {
  font-family: $font-family-gg-regular;
  font-size: $font-size-xxxs;
  line-height: $font-size-xxxs;
  letter-spacing: 0;
  color: inherit;

  @include common-text-params;
}

.sm-description-text {
  font-family: $font-family-gg-regular;
  font-size: $font-size-xs;
  color: $color-gray;
  letter-spacing: 0;
  line-height: 20px;
}

.basic-card {
  padding: 10px;
  border-radius: 10px;
  font-family: $font-family-gg-semi-bold;
  background: $color-light-gray;
  font-size: $font-size-sm;
  color: $color-black;
  letter-spacing: 0;
}

.gg-unordered-list {
  margin: 0;
  padding-left: 0px;
  display: grid;
  gap: 6px;

  ol {
    line-height: 1;
    padding-left: 10px;
    position: relative;
    font-family: $font-family-gg-regular;
    font-size: $font-size-xs;
    color: $color-black;

    &::before {
      content: '';
      position: absolute;
      width: 4px;
      height: 4px;
      left: 0;
      top: 50%;
      transform: translateY(-80%);
      background: $color-dark-pink;
      border-radius: 50%;
    }
  }
}

.gg-content-nowrap {
  white-space: nowrap;
}

a,
.gg-link {
  font-family: $font-family-gg-regular;
  font-size: $font-size-xs;
  color: $color-primary;
  letter-spacing: 0;
  cursor: pointer;
}

.hidden-card-number {
  word-spacing: 8px;
}

.gg-avatar {
  width: 70px;
  height: 70px;
  border-radius: 6px;
  object-fit: cover;
}

.gg-vertical-divider {
  width: 1px;
  height: 100%;
  background: $color-secondary;
}

.gg-popup-content-text {
  font-family: $font-family-gg-regular;
  font-size: $font-size-xs;
  color: $color-black;
  letter-spacing: 0;
  line-height: 20px;
}
