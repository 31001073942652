@import 'variables';

@mixin tbody-content-styles {
  tr {
    padding: 12px 0;
  }

  tr.spacer {
    padding: 0;
    height: 10px;
  }

  td {
    font-family: $font-family-gg-regular;
    font-size: $font-size-xs;
    color: $color-black;
    background-color: $color-light-gray;
    padding: 15px;
    white-space: nowrap;

    &:first-of-type {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-of-type {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

.row-table {
  width: 100%;
  position: relative;
  overflow-x: auto;
  border-collapse: collapse;

  table {
    position: relative;
    width: 100%;
    overflow-x: auto;
  }

  thead {
    color: $color-gray;

    th {
      padding: 10px 15px;
      text-align: left;
      white-space: nowrap;
      font-family: $font-family-gg-regular;
      font-size: $font-size-xs;
      color: $color-gray;
      letter-spacing: 0;
    }
  }

  tbody {
    @include tbody-content-styles;
  }
}

.table--scroll {
  max-width: 100%;
  padding-bottom: 15px;
  overflow-x: auto;
  white-space: nowrap;
}
