@import 'variables';
@import 'general';

.gg-calendar {
  .cal-month-view .cal-days {
    border-top: none;
    border-color: $color-light-gray;
  }

  .cal-month-view .cal-days .cal-cell-row {
    border-bottom: none;
  }

  .cal-month-view .cal-day-cell:not(:last-child) {
    border-right-color: $color-light-gray;
  }

  .cal-month-view .cal-header {
    border: 1px solid $color-light-gray;
    border-bottom: none;
    border-top: none;
  }

  .cal-month-view .cal-header .cal-cell {
    padding: 0 10px 5px 10px;
    text-align: start;
    color: $color-gray;
    background: $color-white;
    user-select: none;

    @extend .xs-content-text;

    &:not(:last-child) {
      border-right: 1px solid $color-light-gray;
    }
  }

  .cal-month-view .cal-day-cell {
    padding: 0 5px;
    min-height: 60px;
    min-width: 95px;
    background: $color-white !important;
    outline: none;

    &:hover {
      .gg-calendar-day {
        background: $color-light-gray;
      }
    }

    &.cal-open {
      .gg-calendar-day {
        background: $color-light-blue !important;
      }
    }

    &.cal-today {
      .gg-calendar-day {
        &__date {
          &::after {
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            background: $color-warn;
            border-radius: 50%;
            position: absolute;
            top: 0;
            right: -8px;
          }
        }

        &__events-container {
          display: flex;
        }
      }
    }

    &.cal-out-month {
      .gg-calendar-day {
        &__date {
          color: $color-gray;
        }
      }
    }
  }
}

.gg-calendar-day {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  user-select: none;
  padding: 5px;

  &__date {
    position: relative;
    display: inline-block;
    font-family: $font-family-gg-semi-bold;
    font-size: $font-size-sm;
    color: $color-black;
    letter-spacing: 0;
  }
}
